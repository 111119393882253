import React from 'react'
import { MenuTabButton } from './MenuTabButton'

export const LoginTabButton = ({ onNavigate }: { onNavigate: () => void }) => {
  return (
    <MenuTabButton
      menu={{ icon: 'login-variant', title: 'enter' }}
      onPress={onNavigate}
    />
  )
}
