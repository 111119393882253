import { colors } from '@pay24/common'
import { useTheme } from '@pay24/common/src/hooks'
import { MenuItem } from '@ui-kitten/components'
import React, { useCallback } from 'react'
import { MenuIcon } from './MenuIcon'
import { MenuTitle } from './MenuTitle'

interface SideMenuItemProps {
  navigation: any
  menu: any
  active: boolean
  onPress?: (path: string) => void
}

export const SideMenuItem = (props: SideMenuItemProps) => {
  const { navigation, menu, active, onPress } = props
  const { isLight, primary } = useTheme()

  const navigate = useCallback(() => {
    if (onPress) {
      onPress(menu.path)
    }
    navigation.navigate(menu.path)
  }, [menu.path, navigation, onPress])

  const iconName = menu.icon
  const textColorActive = primary
  const textColorInactive = isLight ? colors.black : colors.white

  return (
    <MenuItem
      testID={menu.path}
      style={{
        paddingLeft: 16,
        borderBottomWidth: 1,
        backgroundColor: 'transparent',
        borderBottomColor: isLight
          ? 'rgba(0, 0, 0, 0.05)'
          : 'rgba(255, 255, 255, 0.05)',
      }}
      accessoryLeft={
        <MenuIcon
          name={iconName}
          color={active ? textColorActive : colors.metal}
        />
      }
      title={
        <MenuTitle
          title={menu.title || menu.path}
          color={active ? textColorActive : textColorInactive}
        />
      }
      onPress={navigate}
    />
  )
}
