// // import { messaging, getToken } from './firebase-init'
import { EventEmitter } from 'eventemitter3'
// // import { isCharge24, isEvion, isKeep, isPay } from '@pay24/common/src/utils'
//
export const eventEmitter = new EventEmitter()

export const getPushToken = async () => {
  return null
}

export const getInitialNotification = () => {
  return null
}

export async function requestNotificationPermission() {
  return null
}
