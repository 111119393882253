import React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { TextProps, Text } from './Text'
import { colors } from '@pay24/common'

interface LabelProps extends TextProps {
  text: string
  style?: StyleProp<TextStyle>
  bold?: boolean
}

export const Label = (props: LabelProps) => {
  const { text, bold, style, ...rest } = props
  return (
    <Text
      {...rest}
      style={[
        {
          marginBottom: 2,
          color: colors.metal,
          fontSize: 12,
          fontWeight: bold ? '700' : '400',
        },
        style,
      ]}
    >
      {text}
    </Text>
  )
}
