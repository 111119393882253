import { isEvion } from '../utils'
import { useGeoLocation } from './useGeolocation'
import axios from 'axios'
import { useEffect, useState } from 'react'

export function useGeolocationCountry() {
  const { loaded, latitude, longitude } = useGeoLocation()
  const [country, setCountry] = useState('kg')

  useEffect(() => {
    if (loaded) {
      if (isEvion) {
        setCountry('kg')
      } else {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
          )
          .then((response) => {
            setCountry(response.data.address.country_code || 'kg')
          })
          .catch((error) =>
            console.error('Ошибка при обратном геокодировании:', error),
          )
      }
    }
  }, [loaded, isEvion])

  return country.toUpperCase()
}
