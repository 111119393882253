import { MenuItem } from '@ui-kitten/components'
import React from 'react'
import { MenuIcon } from './MenuIcon'
import { MenuTitle } from './MenuTitle'

export const LoginMenuItem = ({ onNavigate }) => {
  return (
    <MenuItem
      testID={'enter'}
      style={{ paddingLeft: 16, backgroundColor: 'transparent' }}
      title={<MenuTitle title={'enter'} />}
      accessoryLeft={<MenuIcon name={'login-variant'} />}
      onPress={onNavigate}
    />
  )
}
