import { colors } from '@pay24/common'
import React from 'react'
import Icon from '../Icon'

interface MenuIconProps {
  name: string
  size?: number
  color?: string
}

export const MenuIcon = (props: MenuIconProps) => {
  const { name, size = 22, color = colors.metal } = props
  return <Icon name={name} size={size} color={color} />
}
