import { colors } from '@pay24/common'
import {
  IndexPath,
  Select,
  SelectItem,
  SelectProps,
} from '@ui-kitten/components'
import React, { memo, useMemo } from 'react'
import { TouchableOpacity } from 'react-native'
import Icon from './Icon'

export interface MultiSelectProps extends SelectProps {
  options: any[]
  onChange: (items: any[]) => void
  onClear?: () => void
  values?: any[]
  selectItemID?: string
}

export const MultiSelect = memo((props: MultiSelectProps) => {
  const {
    options,
    onChange,
    onClear,
    values = [],
    selectItemID,
    ...rest
  } = props

  const value = useMemo(
    () =>
      values
        .map((val) => options.find((option) => option.id === val.id))
        .filter((option) => !!option)
        .map((option) => option.name)
        .join(', '),
    [values, options],
  )

  const selectedIndexes = useMemo(
    () =>
      values
        .map((val) => options.findIndex((option) => option.id === val.id))
        .filter((index) => index !== -1)
        .map((index) => new IndexPath(index)),
    [values, options],
  )

  const accessoryRight =
    onClear && value ? (
      <TouchableOpacity onPress={onClear}>
        <Icon
          style={{
            height: 18,
            width: 18,
            color: colors.metal,
          }}
          name="close"
        />
      </TouchableOpacity>
    ) : undefined

  return (
    <Select
      {...rest}
      value={value}
      multiSelect
      accessoryRight={accessoryRight}
      selectedIndex={selectedIndexes}
      onSelect={(index) => {
        const indexes = index as IndexPath[]
        if (onChange) {
          onChange(
            indexes
              .map((idx) => options.find((_, i) => i === idx.row))
              .filter((option) => !!option),
          )
        }
      }}
    >
      {options.map((r, i) => (
        <SelectItem testID={selectItemID || '' + i} title={r.name} key={r.id} />
      ))}
    </Select>
  )
})
